import { SubTask } from './SubTask';
import { User } from '../user/User';
import { Reviewer } from './Reviewer';
import { Measurement } from './Measurement';
import { RuleResultStatus } from '../rules/RuleResult';
import { GlobalTradeItem } from '../barcodeQR/GlobalTradeItem';

export interface SubTaskMessage
{
  id?: string;

  userId?: string;
  user?: User;

  reviewerId?: string;
  reviewer?: Reviewer;

  subTaskId: string;
  subTask?: SubTask;

  /**
   * Can be 0 for normal comments, but needs a value
   * when the user commented on a picture!
   */
  documentVersion?: number;

  timestampUtc?: Date;
  deletedTimestampUtc?: Date;

  text: string;
  attachment?: SubTaskMessageAttachment;

  /**
   * OBSOLETE
   * Important! Never save in-picture-comments with the
   * coordinates 0/0 - use at least 1/0 to distinguish
   * the comment from 'chat' comments
   */
  x?: number;

  /**
   * OBSOLETE
   * Important! Never save in-picture-comments with the
   * coordinates 0/0 - use at least 1/0 to distinguish
   * the comment from 'chat' comments
   */
  y?: number;

  /**
   * OBSOLETE
   * The page, in case that the message was made
   * on a multipage document, like pdf
   */
  page?: number;

  /**
   * In case the message was done with the Adobe PDF Embed API
   */
  adobeAnnotationData?: AdobeAnnotationData;

  /**
   * In case the message was generated from a rule change event
   */
  ruleChangeData?: RuleChangeData;

  /**
   * In case the message was generated from a barcode scan event
   */
  barCodeData?: BarcodeData;

  /**
   * In case the comment was made on a document other than fullImage.png,
   * we store its name - to make sure we can stay compatible if we add more
   * options ... for example pdf's from other applications like InDesign
   */
  documentName?: string;

  measurement?: Measurement;

  /**
   * To avoid sending emails, used for measurement comments
   */
  isSilentMessage?: boolean;

  /*
  * To use while measure is being saved
  */
  busy?: boolean;

  isSystemMessage?: boolean;
  /**
   * Only if IsSystemMessage = true
   * Approve is obsolete, we keep it for old messages (older than v2.1)
   */
  systemMessageType?: 'Approve' |
    'ApproveWithDocument' |
    'ApproveWithPreapprovedDocument' |
    'ApproveWithoutDocument' |
    'SendToReview' |
    'SendToDesign' |
    'SendToDesignFromEditor' |
    'SendToReviewFromEditor' |
    'SendToDoneFromEditor' |
    'Reopen' |
    'Reject' |
    'RuleStatusChange';
  /**
   * Only if systemMessageType = 'Reject'
   */
  rejectReason?: RejectReason;

  mentionedUsersIds?: string[];
  mentionedReviewersIds?: string[];

  // ui only, set values to be perfomant
  adobeAnnotationType?: 'draw' | 'note' | 'highlight' | 'underline' | 'strikethrough';

}

export enum RejectReason
{
  OwnerError = 'OwnerError',
  DesignerError = 'DesignerError',
  SpecificationsError = 'SpecificationsError',
  Other = 'Other'
}

export interface AdobeAnnotationData
{
  /**
   * Internal id used by adobe to identify each message on the pdf
   */
  adobeAnnotationId: string;

  /**
   * Json object with the annotation metadata (coordinates, annotation type, colors, etc)
   * mapped to "target" in the adobe model data
   */
  metadataJson: string;

  /**
   * Json object with the stylesheet of the annotation
   * used by some types of Adobe Annotations
   */
  stylesheetJson?: string;
}

export interface RuleChangeData
{
  ruleConfigId: string;
  ruleName: string;
  previousStatus: RuleResultStatus;
  newStatus: RuleResultStatus;
}

export interface BarcodeData
{
  gtin: string;
  format: string;
  status: 'Valid' | 'Invalid' | 'Unvalidable' | 'Unverified' | 'Unreadable';
  globalTradeItem: GlobalTradeItem;
}

export interface SubTaskMessageFilter
{
  subTaskId: string;
  page: number;
}

export interface SubTaskMessageResponse
{
  page: number;
  pageCount: number;
  pageSize: number;
  totalResult: number;
  messages: SubTaskMessage[];
}

export interface SubTaskMessageAttachment
{
  fileName: string;
  filePath?: string;
  // ui only
  file: File;
}
