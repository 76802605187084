import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GlobalTradeItem } from '../_models/barcodeQR/GlobalTradeItem';

@Injectable({
  providedIn: 'root'
})
export class BarcodeQRService
{

  constructor(private authService: AuthService)
  {}

  async getGlobalTradeItem(gtin: string): Promise<GlobalTradeItem>
  {
    let result: GlobalTradeItem;

    // Extract GTIN from URL if applicable
    gtin = this.extractGtinFromUrl(gtin);

    try
    {
      result = await this.authService.authGet<GlobalTradeItem>(
        `/api/v2/barcodeQR/GetGlobalTradeItem?gtin=${gtin}`
      );
      this.processGlobalTradeItem(result);
    }
    catch (error)
    {
      if (error?.error !== 'GlobalTradeItemNotFound')
      {
        throw error; // GS1 connection error
      }
    }
    return result;
  }

  private extractGtinFromUrl(gtin: string): string
  {
    const gtinMatch = gtin.match(/\/01\/(\d{13,14})/);
    return gtinMatch ? gtinMatch[1] : gtin;
  }

  private processGlobalTradeItem(item: GlobalTradeItem): void
  {
    item.targetMarketList = item.targetMarket?.map(tm => tm.name).join(', ');
    item.octagonList = item.octagons?.filter(o => o.active).map(o => o.description).join(', ');
    item.netContent = item.netContent ? parseFloat(item.netContent).toString() : item.netContent;
    item.netContent = item.netContent === '0' ? '' : item.netContent;
    item.unitOfMeasure = item.unitOfMeasure?.replace(/\(.*?\)\s*/, '');
    item.package = item.package?.replace(/\(.*?\)\s*/, '');
  }

}
